import React, { useEffect } from "react";
import { AnimationType } from '../../types/animations';
import { Icon } from "@iconify/react";
import "bootstrap/dist/css/bootstrap.min.css"; 
const bootstrap = require("bootstrap/dist/js/bootstrap.bundle.min"); // Import Bootstrap JS


interface AnimationControlPanelProps {
  setSelectedAnimation: React.Dispatch<React.SetStateAction<AnimationType>>;
  animationSpeed: number;
  setAnimationSpeed: (speed: number) => void;
}

const AnimationControlPanel: React.FC<AnimationControlPanelProps> = ({
  setSelectedAnimation,
  animationSpeed,
  setAnimationSpeed,
}) => {
  // Initialize Bootstrap tooltips
  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = Array.from(tooltipTriggerList).map((tooltipTriggerEl) =>
      new bootstrap.Tooltip(tooltipTriggerEl) // Using `bootstrap` from require import
    );
    return () => {
      tooltipList.forEach((tooltip) => tooltip.dispose());
    };
  }, []);

  const animations = [
    { value: "circles", label: "Animate Circles on Arrows", icon: "solar:medal-star-circle-outline" }, 
    { value: "arrows", label: "Animate Arrow Draw", icon: "solar:arrow-right-outline" },
    { value: "dashedarrows", label: "Animate Dashed Arrow Moving", icon: "svg-spinners:bars-fade" },
    { value: "dotedarrows", label: "Animate Doted Arrow Moving", icon: "svg-spinners:3-dots-fade" },
    { value: "basic", label: "Basic Animation (Opacity)", icon: "solar:radial-blur-linear" },
    { value: "parallax", label: "Parallax Effect", icon: "solar:layers-minimalistic-line-duotone" },
    { value: "text", label: "Text Animation", icon: "solar:text-field-focus-linear" },
    { value: "wavinganimation", label: "This effect simulates a sine wave running through the elements.", icon: "solar:soundwave-line-duotone"},
    { value: "bouncing", label: "This animation gives an element a bouncing effect, similar to a ball bouncing.", icon: "svg-spinners:bouncing-ball"},
    { value: "colors", label: "This animation changes the color of the elements.", icon: "solar:palette-line-duotone" },
    { value: "popping", label: "This animation simulates an element popping or expanding.", icon: "solar:bomb-broken" },
    { value: "elasticbounce", label: "This animation simulates an elastic bounce effect.", icon: "solar:volleyball-2-linear" },
    { value: "swirl", label: "This animation simulates a swirl effect.", icon: "solar:tornado-linear" },
    { value: "flicker", label: "This animation simulates a flickering effect.", icon: "solar:lightbulb-broken" },
    { value: "rolling", label: "This animation simulates a rolling effect.", icon: "solar:rolling-pin-linear" },
    { value: "blinking", label: "This animation simulates a blinking effect.", icon: "solar:eye-broken" },
    { value: "windmill", label: "This animation simulates a windmill effect.", icon: "svg-spinners:wind-toy" },
    { value: "pulse", label: "This animation simulates a pulse effect.", icon: "line-md:star-pulsating-twotone-loop" },
    { value: "orbiting", label: "This animation simulates an orbiting effect.", icon: "eos-icons:atom-electron" },
    { value: "explosion", label: "This animation simulates an explosion effect.", icon: "game-icons:bright-explosion"},
    { value: "glow", label: "This animation simulates a glow effect.", icon: "emojione:glowing-star" },
    { value: "zoominout", label: "This animation simulates a zoom in and out effect.", icon: "solar:magnifer-zoom-in-line-duotone" },
    { value: "shimmer", label: "This animation simulates a shimmer effect.", icon: "token-branded:shimmer-evm" },
    { value: "firefly", label: "This animation simulates a firefly effect.", icon: "noto-v1:fire" },
    { value: "ripple", label: "This animation simulates a ripple effect.", icon: "solar:water-linear" },
    { value: "flip", label: "This animation simulates a flip effect.", icon: "solar:flip-horizontal-outline" },
    { value: "git", label: "This animation simulates a git flow.", icon: "logos:git" },
    { value: "piechart", label: "This animation simulates a pie chart animations.", icon: "flat-color-icons:pie-chart" },
    { value: "mindmap", label: "This animation simulates a mind map animations.", icon: "flat-color-icons:mind-map" },
    { value: "sequnceflow", label: "This animation simulates a sequence flow animations.", icon: "arcticons:pagan-music-sequencer"},
    { value: "rocket", label: "This animation simulates a rocket animations.", icon: "fluent-emoji:rocket"},
    { value: "wobble", label: "This animation simulates a wobble effect.", icon: "fluent-emoji-flat:woman-dancing-dark" },
    { value: "float", label: "This animation simulates a float effect.", icon: "icon-park:float" },
    { value: "moveup", label: "This animation simulates movein up effect.", icon: "line-md:arrow-up" },
    { value: "movedown", label: "This animation simulates movein down effect.", icon: "line-md:arrow-down" },
    { value: "moveleft", label: "This animation simulates movein left effect.", icon: "line-md:arrow-left" },
    { value: "moveright", label: "This animation simulates movein right effect.", icon: "line-md:arrow-right" },
    { value: "rotatex", label: "This animation simulates rotate x effect.", icon: "eos-icons:arrow-rotate" },
    { value: "rotatey", label: "This animation simulates rotate y effect.", icon: "eos-icons:arrow-rotate" },
    { value: "rotatez", label: "This animation simulates rotate z effect.", icon: "eos-icons:arrow-rotate" },
    { value: "scalex", label: "This animation simulates scale x effect.", icon: "line-md:arrows-long-diagonal-rotated" },
    { value: "scaley", label: "This animation simulates scale y effect.", icon: "line-md:arrows-long-diagonal-rotated" },
    { value: "scalez", label: "This animation simulates scale z effect.", icon: "line-md:arrows-long-diagonal-rotated" },
    { value: "skewx", label: "This animation simulates skew x effect.", icon: "mingcute:x-skew-line" },
    { value: "skewy", label: "This animation simulates skew y effect.", icon: "tabler:skew-y" },
    { value: "skewz", label: "This animation simulates skew z effect.", icon: "hugeicons:skew" },
    { value: "sway", label: "This animation simulates sway effect.", icon: "simple-icons:sway" },
    { value: "translatex", label: "This animation simulates translate effect.", icon: "gis:translate-x" },
    { value: "translatey", label: "This animation simulates translate y effect.", icon: "gis:translate-y" },
    { value: "translatez", label: "This animation simulates translate z effect.", icon: "gis:translate" },
    { value: "none", label: "No Animation", icon: "solar:close-circle-outline" },
  ];

  return (
    <div>
<div className="container  mb-3">
  <div className="mt-4">
    <label htmlFor="animationSpeed" className="form-label">
      Animation Speed
    </label>
    <input
      type="range"
      className="form-range"
      min="1"
      max="10"
      value={animationSpeed}
      onChange={(e) => setAnimationSpeed(Number(e.target.value))}
    />
    <span>{animationSpeed}s</span>
  </div>
  <span className="sidebar-divider"></span>
  <label className="form-label">Select Animation</label>
    <div className="row">
      {animations.map((animation) => (
        <div
          key={animation.value}
          className="col-3 d-flex flex-column align-items-center mb-2"
          role="button"
          aria-label={animation.label}
          onClick={() => setSelectedAnimation(animation.value as any)}
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={animation.label}
          style={{
            cursor: "pointer",
            width: "60px", // icon width
            height: "60px", // icon height
          }}
        >
          <Icon icon={animation.icon} width="36" height="36" />
        </div>
      ))}
    </div>
  </div>
</div>
  );
};

export default AnimationControlPanel;
