import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import RenderAnimation from "./components/RenderAnimation";
import AnimationControlPanel from "./components/animations/AnimationControlPanel";
import CircleRadiusControl from "./components/animations/CircleRadiusControl";
import { exportCompressedGif, startRecording } from "./utils/gifCaptureUtils";
import GifDurationControl from "./components/GifDurationControl";
import ShapeSelector from "./components/ShapeSelector";
import { Icon } from "@iconify/react"; // Using Iconify for React icons
import './App.css'; // CSS file containing the provided styles
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure CSS is imported
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Ensure JS for tooltips, modals, etc., is imported
import { FaFileExport, FaCompress, FaHeart, FaBars } from 'react-icons/fa';
import MermaidRenderer from './components/mermaid/MermaidRenderer';
import RemoveAnimation from "./components/animations/RemoveAnimation";
import ExportSvg from "./components/ExportSvg";
import templates from "./svgTemplates.json";
import SVGOptionsModal from "./components/SVGOptionsModal";
import GifExporter from "./components/GifExporter";
import { Accordion } from "react-bootstrap";
import { useTooltips } from './hooks/useTooltips';
import { AnimationType } from './types/animations';

gsap.registerPlugin(MotionPathPlugin);

const App: React.FC = () => {
  const [svgContent, setSvgContent] = useState<string | null>(null);
  const svgContainerRef = useRef<HTMLDivElement | null>(null);
  const [circleColor, setCircleColor] = useState<string>("blue");
  const [circleRadius, setCircleRadius] = useState<number>(5);
  const [animationSpeed, setAnimationSpeed] = useState<number>(2);
  const [strokeColor, setStrokeColor] = useState<string>("#1e1e1e");
  const [shape, setShape] = useState<"circle" | "square" | "triangle" | "star" | "oval" | "hexagon">("circle");
  const [selectedAnimation, setSelectedAnimation] = useState<AnimationType>(null);
  const [gifDuration, setGifDuration] = useState<number>(10);
  const [uploadedImage, setUploadedImage] = useState<string | undefined>();
  const [imageWidth, setImageWidth] = useState<number>(20);
  const [imageHeight, setImageHeight] = useState<number>(20);
  const [expandedPanel, setExpandedPanel] = useState<string | null>(null); // Track which panel is expanded
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(false); // Toggle state for sidebar
  const [selectedElement, setSelectedElement] = useState<SVGElement | null>(null);
  const [zoomLevel, setZoomLevel] = useState<number>(1);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [exportProgress, setExportProgress] = useState<number>(0);
  const [mermaidSyntax, setMermaidSyntax] = useState<string>(''); // State to store Mermaid syntax
  const [showMermaid, setShowMermaid] = useState<boolean>(false); // State to control rendering
  const [sidebarActive, setSidebarActive] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isPanning, setIsPanning] = useState(false);
  const [panPosition, setPanPosition] = useState({ x: 0, y: 0 });
  const [lastMousePosition, setLastMousePosition] = useState({ x: 0, y: 0 });
  const [showOverlay, setShowOverlay] = useState(false);
  const [isPanelVisible, setIsPanelVisible] = useState(!isMobile);

  useTooltips();

  // Zoom controls
  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 0.1, 3)); // Max zoom: 300%
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.1, 0.5)); // Min zoom: 50%
  };

  const handleResetZoom = () => {
    setZoomLevel(1); // Reset zoom to 100%
  };

  useEffect(() => {
    // Listen for resize events
    const handleResize = () => {
      const isMobileView = window.innerWidth <= 768;
      setIsMobile(isMobileView);
      setSidebarActive(window.innerWidth > 768);
      setIsPanelVisible(!isMobileView); // Hide panel on mobile by default
    };
    
    window.addEventListener("resize", handleResize);
    
    // Clean up listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

useEffect(() => {
  const handleScrollZoom = (e: WheelEvent) => {
    if (e.ctrlKey) {
      e.preventDefault(); // Prevent the default zoom behavior
      if (e.deltaY < 0) {
        handleZoomIn();
      } else {
        handleZoomOut();
      }
    }
  };


  // Attach the event listener for wheel events
  window.addEventListener("wheel", handleScrollZoom, { passive: false }); // Set passive to false to allow preventDefault()

  return () => {
    window.removeEventListener("wheel", handleScrollZoom); // Clean up the event listener
  };
}, []);
  
  const sidebarItems = [
    { icon: "solar:cloud-upload-line-duotone", label: "Uploads" },
    { icon: "solar:box-minimalistic-broken", label: "Shapes" },
    { icon: "solar:clapperboard-line-duotone", label: "Animations" },
    { icon: "solar:videocamera-record-linear", label: "Export" },
  ];

  // Handle image upload for animation
  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleElementClick = (event: React.MouseEvent<SVGElement>) => {
    const clickedElement = event.target as SVGElement;
    setSelectedElement(clickedElement);
    console.log("slected element",clickedElement);
  };

   // Automatically updates the SVG container with uploaded content and attaches event listeners
  useEffect(() => {
    if (svgContent && svgContainerRef.current) {
      svgContainerRef.current.innerHTML = svgContent;

      // Add click handlers for selecting SVG elements once the SVG is injected
      const svgElement = svgContainerRef.current.querySelector("svg");
      if (svgElement) {
        const allSvgElements = svgElement.querySelectorAll(`
          circle, 
          rect, 
          path, 
          image, 
          line, 
          polygon, 
          polyline, 
          ellipse, 
          text, 
          g, 
          use, 
          foreignObject, 
          tspan, 
          symbol
        `);
        allSvgElements.forEach((element) => {
          element.addEventListener("click", handleElementClick as any);
        });

        return () => {
          allSvgElements.forEach((element) => {
            element.removeEventListener("click", handleElementClick as any);
          });
        };
      }
    }
  }, [svgContent]);
  // Handle element click for Mermaid-rendered diagrams
  const handleMermaidElementClick = (element: SVGElement) => {
    setSelectedElement(element);
    console.log("selected mermaid",element);
  };

  const handleMermaidRender = () => {
    console.log('Mermaid diagram rendered');
  };
  const [uploadedImageForMermaid, setUploadedImageForMermaid] = useState<string | null>(null);
  const [imageSize, setImageSize] = useState({ width: 50, height: 50 });

  // Handle image upload
  const handleImageUploadForMermaid = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedImageForMermaid(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    // Only enable panning when zoomed in
    if (zoomLevel > 1) {
      setIsPanning(true);
      setLastMousePosition({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (isPanning) {
      const deltaX = e.clientX - lastMousePosition.x;
      const deltaY = e.clientY - lastMousePosition.y;
      
      setPanPosition(prev => ({
        x: prev.x + deltaX,
        y: prev.y + deltaY
      }));
      
      setLastMousePosition({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseUp = () => {
    setIsPanning(false);
  };

  // Update the mouse wheel handler to reset pan position when zooming out completely
  useEffect(() => {
    const handleScrollZoom = (e: WheelEvent) => {
      if (e.ctrlKey) {
        e.preventDefault();
        if (e.deltaY < 0) {
          handleZoomIn();
        } else {
          handleZoomOut();
          // Reset pan position if zooming out to normal size
          if (zoomLevel <= 1) {
            setPanPosition({ x: 0, y: 0 });
          }
        }
      }
    };

    window.addEventListener("wheel", handleScrollZoom, { passive: false });
    return () => {
      window.removeEventListener("wheel", handleScrollZoom);
    };
  }, [zoomLevel]);

  const toggleSidebar = () => {
    setSidebarActive(!sidebarActive);
    setShowOverlay(!sidebarActive);
  };

  const handleOverlayClick = () => {
    setSidebarActive(false);
    setShowOverlay(false);
  };

  const handleExportClick = () => {
    startRecording(svgContainerRef, gifDuration * 1000, setIsExporting, setExportProgress);
  };

  const handleCompressedExportClick = () => {
    exportCompressedGif(svgContainerRef, gifDuration * 1000, setIsExporting, setExportProgress);
  };

  const handleSidebarItemClick = (item: any) => {
    setExpandedPanel(expandedPanel === item.label ? null : item.label);
    if (isMobile) {
      setSidebarActive(false);
      setShowOverlay(false);
      setIsPanelVisible(true); // Show panel when selecting an item
    }
  };

  const togglePanel = () => {
    setIsPanelVisible(!isPanelVisible);
  };

  return (
    <div className="app-container">
      {/* Burger Menu Icon */}
      <div
        className="burger-icon"
        onClick={toggleSidebar}
        style={{
          display: isMobile ? "block" : "none",
          position: "fixed",
          top: "10px",
          left: "10px",
          fontSize: "24px",
          color: "#333",
          zIndex: 1000,
          width: "40px",
          height: "40px",
          lineHeight: "40px",
          textAlign: "center",
          backgroundColor: "#fff",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        <FaBars />
      </div>

      {/* Overlay for mobile */}
      {isMobile && (
        <div 
          className={`sidebar-overlay ${showOverlay ? 'active' : ''}`}
          onClick={handleOverlayClick}
        />
      )}

      {/* Sidebar */}
      <div className={`slim-sidebar ${sidebarActive ? 'active' : ''}`}>
        <img src='./logo.png' alt="Logo" style={{ width: "50px", height: "auto" }} />
        <div className="sidebar-header"></div>
        <ul className="sidebar-tooltip">
          {sidebarItems.map((item, index) => (
            <li 
              key={index} 
              className={`nav-item ${expandedPanel === item.label ? "active" : ""}`}
              onClick={() => handleSidebarItemClick(item)}
            >
              <a
                href="#"
                className="nav-link"
                title={item.label}
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                onClick={(e) => e.preventDefault()}
              >
                <Icon icon={item.icon} width="24" height="24" />
                <span className="hide-menu">{item.label}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>

      {/* Panel toggle button - only visible on mobile */}
      {isMobile && (
        <button 
          className="panel-toggle"
          onClick={togglePanel}
          style={{
            display: expandedPanel ? 'flex' : 'none' // Only show when there's an expanded panel
          }}
        >
          <Icon 
            icon={isPanelVisible ? "mdi:close" : "mdi:menu"} 
            width="24" 
            height="24" 
          />
        </button>
      )}

      {/* Panel overlay - only visible on mobile */}
      {isMobile && (
        <div 
          className={`panel-overlay ${isPanelVisible ? 'active' : ''}`}
          onClick={() => setIsPanelVisible(false)}
        />
      )}

      {/* Main Content */}
      <div className={`main-content ${sidebarCollapsed ? "expanded" : ""}`}>
        <div
          ref={svgContainerRef}
          className="svg-container"
          style={{
            transform: `scale(${zoomLevel}) translate(${panPosition.x}px, ${panPosition.y}px)`,
            transformOrigin: "center",
            transition: "transform 0.3s ease-in-out",
            cursor: zoomLevel > 1 ? (isPanning ? "grabbing" : "grab") : "default",
          }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          {/* Render Mermaid diagram */}
          {showMermaid && (
            <MermaidRenderer
              mermaidSyntax={mermaidSyntax}
              svgContainerRef={svgContainerRef}
              onElementClick={handleMermaidElementClick}
              onRender={handleMermaidRender} 
              selectedAnimation={selectedAnimation} 
              animationSpeed={animationSpeed}
              />
          )}
          {selectedElement && (
            <div>
              <p>Selected Element: {selectedElement.tagName}</p>
            </div>
          )}
        </div>
        {/* Render Animations */}
        <RenderAnimation
          svgElement={svgContainerRef.current?.querySelector('svg') as SVGElement}
          selectedElement={selectedElement} // The clicked Mermaid element
          selectedAnimation={selectedAnimation}
          circleColor={circleColor}
          circleRadius={circleRadius}
          animationSpeed={animationSpeed}
          strokeColor={strokeColor}
          shape={shape}
          uploadedImage={uploadedImage}
          imageWidth={imageWidth}
          imageHeight={imageHeight}
        />
      </div>
      {isExporting && (
        <div className="loading-overlay">
          <div className="loading-content">
            <p>Exporting GIF: {exportProgress}%</p>
            <div className="progress-bar">
              <div className="progress-bar-fill" style={{ width: `${exportProgress}%` }}></div>
            </div>
          </div>
        </div>
      )}
      {/* Dynamic Panel Content */}
      <div className={`expanded-panel ${isPanelVisible ? 'active' : ''}`}>    
        {expandedPanel === "Uploads" && (
          <div className="panel-content">
            <div className="zoom-control">
              <button className="zoom-btn" onClick={handleZoomOut}>
                –
              </button>
              <span className="zoom-display">{Math.round(zoomLevel * 100)}%</span>
              <button className="zoom-btn" onClick={handleZoomIn}>
                +
              </button>
            </div>     
            <SVGOptionsModal
              templates={templates}
              setSvgContent={setSvgContent}
              mermaidSyntax={mermaidSyntax}
              setMermaidSyntax={setMermaidSyntax}
              setShowMermaid={setShowMermaid}
            />

            {/* Image Upload Section
            <div className="image-upload-section mt-3">
              <label htmlFor="uploadImage" className="form-label">
                Upload Image
              </label>
              <input
                type="file"
                accept="image/*"
                id="uploadImage"
                onChange={handleImageUploadForMermaid}
                className="form-control mb-3"
              />

              {uploadedImageForMermaid && (
                <div>
                  <label>Image Size:</label>
                  <div className="d-flex mb-3">
                    <input
                      type="number"
                      className="form-control me-2"
                      value={imageSize.width}
                      onChange={(e) =>
                        setImageSize({ ...imageSize, width: parseInt(e.target.value) })
                      }
                      placeholder="Width"
                    />
                    <input
                      type="number"
                      className="form-control"
                      value={imageSize.height}
                      onChange={(e) =>
                        setImageSize({ ...imageSize, height: parseInt(e.target.value) })
                      }
                      placeholder="Height"
                    />
                  </div>
                </div>
              )}
            </div> */}

            {/* {uploadedImage && (
              <>
                <div className="form-group">
                  <label>Image Width</label>
                  <input
                    type="number"
                    value={imageWidth}
                    onChange={(e) => setImageWidth(Number(e.target.value))}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label>Image Height</label>
                  <input
                    type="number"
                    value={imageHeight}
                    onChange={(e) => setImageHeight(Number(e.target.value))}
                    className="form-control"
                  />
                </div>
              </>
            )} */}
          </div>
        )}
        {expandedPanel === "Shapes" && (
          <div className="panel-content">
            <ShapeSelector shape={shape} setShape={setShape} />
            <div className="form-group">
              <label>Shape Color</label>
              <input type="color" value={circleColor} onChange={(e) => setCircleColor(e.target.value)} className="form-control" />
            </div>
            <div className="form-group">
              <label>Stroke Color</label>
              <input type="color" value={strokeColor} onChange={(e) => setStrokeColor(e.target.value)} className="form-control" />
            </div>
            <CircleRadiusControl circleRadius={circleRadius} setCircleRadius={setCircleRadius} />
            <div className="form-group">
              <label htmlFor="uploadImage">Upload Custom Shape Image</label>
              <input type="file" className="form-control" accept="image/*" id="uploadImage" onChange={handleImageUpload} />
            </div>
          </div>
        )}

        {expandedPanel === "Animations" && (
          <div className="container panel-content">
            <AnimationControlPanel
              setSelectedAnimation={setSelectedAnimation}
              animationSpeed={animationSpeed}
              setAnimationSpeed={setAnimationSpeed}
            />
            {svgContainerRef.current && 
              svgContainerRef.current.querySelector("svg") && (
                <RemoveAnimation svgElement={svgContainerRef.current.querySelector("svg") as SVGElement} />
              )}
          </div>
        )}
        {expandedPanel === "Export" && (
          <div className="container panel-content">
            <GifDurationControl duration={gifDuration} setDuration={setGifDuration} />

            <div className="button-group">
              <button
                className="styled-button"
                onClick={handleExportClick}
              >
                <FaFileExport style={{ marginRight: "8px" }} />
                Export GIF
              </button>
              <button
                className="styled-button"
                onClick={handleCompressedExportClick}
              >
                <FaCompress style={{ marginRight: "8px" }} />
                Export Compressed Gif
              </button>
              <ExportSvg svgElement={svgContainerRef.current?.querySelector('svg')} />
              <GifExporter svgContainerRef={svgContainerRef} />
            </div>
            {/* Sponsor Card Accordion */}
            <span className="sidebar-divider"></span>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <FaHeart style={{ marginRight: "8px" }} />
                  Support the Developer
                </Accordion.Header>
                <Accordion.Body>
                  <iframe
                    src="https://github.com/sponsors/error505/card"
                    title="Sponsor error505"
                    height="225"
                    width="100%"
                    style={{ border: "0" }}
                  ></iframe>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        )}
      </div>
    </div>
  );
};

export default App;
