import { useEffect, useState } from "react";
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

gsap.registerPlugin(MotionPathPlugin);

interface CirclePathAnimationProps {
  svgElement: SVGElement;
  selectedElement: SVGElement | null;
  circleColor: string;
  circleRadius: number;
  animationSpeed: number;
  strokeColor: string;
  shape: "circle" | "square" | "triangle" | "star" | "oval" | "hexagon" | null;
  uploadedImage?: string;
  imageWidth?: number;
  imageHeight?: number;
}

const CirclePathAnimation: React.FC<CirclePathAnimationProps> = ({
  svgElement,
  selectedElement,
  circleColor,
  circleRadius,
  animationSpeed,
  strokeColor,
  shape,
  uploadedImage,
  imageWidth = 20,
  imageHeight = 20,
}) => {
  const [selectedElements, setSelectedElements] = useState<SVGElement[]>([]);
  const [animatedElements, setAnimatedElements] = useState<SVGElement[]>([]); // To track already animated elements

  useEffect(() => {
    if (selectedElement) {
      // Add selected element only if it's not already added
      if (selectedElement instanceof SVGPathElement && !animatedElements.includes(selectedElement)) {
        setSelectedElements((prevElements) => [...prevElements, selectedElement]);
      } else {
        console.warn("Selected element is not a valid SVGPathElement or already animated:", selectedElement);
      }
    }
  }, [selectedElement, animatedElements]);

  useEffect(() => {
    selectedElements.forEach((pathElement) => {
      try {
        const path = pathElement as SVGPathElement;
        
        // Check if the path is valid before proceeding
        if (!path || !(path instanceof SVGPathElement) || animatedElements.includes(pathElement)) {
          console.warn("Invalid or already animated path element:", pathElement);
          return;
        }

        path.setAttribute("stroke", strokeColor); // Set stroke color dynamically

        let movingElement: SVGElement | HTMLImageElement | null = null;

        if (uploadedImage) {
          const image = document.createElementNS("http://www.w3.org/2000/svg", "image");
          image.setAttribute("href", uploadedImage);
          const size = circleRadius * 2;
          image.setAttribute("width", `${size}px`);
          image.setAttribute("height", `${size}px`);
          image.setAttribute("x", `-${size / 2}`);
          image.setAttribute("y", `-${size / 2}`);
          svgElement.appendChild(image);
          movingElement = image;
        } else if (shape === "circle") {
          const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
          circle.setAttribute("r", `${circleRadius}`);
          circle.setAttribute("fill", circleColor);
          svgElement.appendChild(circle);
          movingElement = circle;
        } else if (shape === "square") {
          const square = document.createElementNS("http://www.w3.org/2000/svg", "rect");
          square.setAttribute("width", `${circleRadius * 2}`);
          square.setAttribute("height", `${circleRadius * 2}`);
          square.setAttribute("fill", circleColor);
          svgElement.appendChild(square);
          movingElement = square;
        } else if (shape === "triangle") {
          const triangle = document.createElementNS("http://www.w3.org/2000/svg", "polygon");
          const points = `${-circleRadius},${circleRadius} ${circleRadius},${circleRadius} 0,${-circleRadius}`;
          triangle.setAttribute("points", points);
          triangle.setAttribute("fill", circleColor);
          svgElement.appendChild(triangle);
          movingElement = triangle;
        } else if (shape === "star") {
          const star = document.createElementNS("http://www.w3.org/2000/svg", "polygon");
          const starPoints = calculateStarPoints(5, circleRadius, circleRadius / 2);
          star.setAttribute("points", starPoints);
          star.setAttribute("fill", circleColor);
          svgElement.appendChild(star);
          movingElement = star;
        } else if (shape === "oval") {
          const oval = document.createElementNS("http://www.w3.org/2000/svg", "ellipse");
          oval.setAttribute("rx", `${circleRadius}`);
          oval.setAttribute("ry", `${circleRadius / 2}`);
          oval.setAttribute("fill", circleColor);
          svgElement.appendChild(oval);
          movingElement = oval;
        } else if (shape === "hexagon") {
          const hexagon = document.createElementNS("http://www.w3.org/2000/svg", "polygon");
          const hexagonPoints = calculateHexagonPoints(circleRadius);
          hexagon.setAttribute("points", hexagonPoints);
          hexagon.setAttribute("fill", circleColor);
          svgElement.appendChild(hexagon);
          movingElement = hexagon;
        }
        // Clear any previous animations on the moving element
        gsap.killTweensOf(movingElement);
        // Apply animation to the chosen element
        if (movingElement) {
          const animateElement = () => {
            console.log("Starting animation for element:", movingElement);
            gsap.to(movingElement, {
              motionPath: {
                path: path,
                align: path,
                autoRotate: true,
                alignOrigin: [0.5, 0.5],
              },
              duration: animationSpeed,
              ease: "power1.inOut",
              onComplete: animateElement, // Restart the animation on completion
            });
          };

          animateElement(); // Start the animation

          // Add to animated elements array after animation is applied
          setAnimatedElements((prevAnimated) => [...prevAnimated, pathElement]);
        }
      } catch (error) {
        console.error("Error during animation:", error);
      }
    });
  }, [selectedElements, svgElement, circleColor, circleRadius, animationSpeed, strokeColor, shape, uploadedImage, imageWidth, imageHeight, animatedElements]);

  // Functions to calculate points for hexagon and star shapes
  function calculateHexagonPoints(radius: number): string {
    let points = "";
    for (let i = 0; i < 6; i++) {
      const angle = (Math.PI / 3) * i;
      const x = radius * Math.cos(angle);
      const y = radius * Math.sin(angle);
      points += `${x},${y} `;
    }
    return points.trim();
  }

  function calculateStarPoints(points: number, outerRadius: number, innerRadius: number): string {
    const step = Math.PI / points;
    let path = "";
    for (let i = 0; i < 2 * points; i++) {
      const radius = i % 2 === 0 ? outerRadius : innerRadius;
      const x = radius * Math.sin(i * step);
      const y = -radius * Math.cos(i * step);
      path += `${x},${y} `;
    }
    return path.trim();
  }

  return null;
};

export default CirclePathAnimation;
