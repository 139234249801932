import { useEffect } from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// Make it a proper module
export interface TooltipOptions {
  trigger?: string;
  container?: string;
}

// Import bootstrap properly
declare global {
  interface Window {
    bootstrap: any;
  }
}

export const useTooltips = () => {
  useEffect(() => {
    // Ensure bootstrap is available
    if (!window.bootstrap) {
      console.error('Bootstrap is not loaded');
      return;
    }

    const disposeTooltips = () => {
      const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
      tooltips.forEach(element => {
        const tooltip = window.bootstrap.Tooltip.getInstance(element);
        if (tooltip) {
          tooltip.dispose();
        }
      });
    };

    const initializeTooltips = () => {
      disposeTooltips(); // Clean up existing tooltips first
      const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
      tooltips.forEach(element => {
        new window.bootstrap.Tooltip(element, {
          trigger: 'hover',
          container: 'body',
          animation: false // Disable animation to prevent issues
        });
      });
    };

    // Initialize tooltips with a small delay to ensure DOM is ready
    const timeoutId = setTimeout(() => {
      initializeTooltips();
    }, 100);

    // Add global click handler to hide tooltips
    const handleClick = () => {
      disposeTooltips();
      initializeTooltips();
    };

    document.addEventListener('click', handleClick);

    // Cleanup
    return () => {
      clearTimeout(timeoutId);
      document.removeEventListener('click', handleClick);
      disposeTooltips();
    };
  }, []);

  return null;
};

// Make it a module
export default useTooltips; 